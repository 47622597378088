import { render, staticRenderFns } from "./tingXiaHList.vue?vue&type=template&id=d8741004&scoped=true"
import script from "./tingXiaHList.vue?vue&type=script&lang=js"
export * from "./tingXiaHList.vue?vue&type=script&lang=js"
import style0 from "./tingXiaHList.vue?vue&type=style&index=0&id=d8741004&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8741004",
  null
  
)

export default component.exports