<template>
  <div class="tingXiaH">
    <div class="partyBuilding-main">
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.createTime }}</div>
            <div class="item-lbr">
              <div class="img"><img src="../img/icon-read.png" alt="" /></div>
              <div class="txt">{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "tingXiaH",
  data() {
    return {
      type: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 5,
      listData: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.type = this.$route.query.type;
  },
  mounted() {
    this.getPartyList();
  },
  methods: {
    toRedHousekeeper() {
      this.$router.push({
        name: "redUnion",
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "tingXiaHDetail",
        query: {
          id: item.id,
        },
      });
    },

    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: this.type, // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage++,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaH {
  min-height: 100vh;
}
.tingXiaH {
  .partyBuilding-main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
